import api from "../baseurl";

const Show = async () => {
    
    const status = await api.get("consultation-types/index").json();
    return status ;
    
  };
  
  export default Show ; 
