import React from 'react';
import PropTypes from 'prop-types';
import {Button, TextField, Grid, Typography} from "@material-ui/core"
//import Radio from '@material-ui/core/Radio';
import { withStyles} from '@material-ui/core/styles';

const styles = theme =>({
    formControl: {
      //margin: theme.spacing(0),
      margin: theme.spacing(1),
    },
    title: {
      fontSize: 14,
    },
    margin: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    },
    backButton: {
      marginLeft: theme.spacing(1),
    },
  });


export class EnterRFCName extends React.Component{
  constructor(props){
    super(props);
    this.state ={
      hidden: ''
    }
  }
  componentDidMount(){
    this.setState({hidden: this.props.values.rfc_name})
  }
    render() {
      const { values, handleChange, manualChange} = this.props;
      const { classes } = this.props; 
        if(!values) return null
        return(
            <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
           {/*    <RadioGroup defaultValue={values.boolrfc_name} aria-label="rfc" className={classes.formControl} onChange={handleChange('boolrfc_name')}>
                  <FormControlLabel
                  value= 'RFC'
                  control={<Radio color="primary" />}
                  label="Con RFC"
                  //labelPlacement="start" 
                  />
                  <FormControlLabel
                  value= 'SinRFC'
                  control={<Radio color="primary" />}
                  label="Con Nombre"
                  //labelPlacement="start"
                  />
              </RadioGroup> */}
              <Typography variant= 'h5'>Conoce su RFC?</Typography>
              <Grid item className={classes.margin}>
              <Button variant="contained" color="primary" 
              onClick={()=>{this.setState({hidden: true}); manualChange({var: 'rfc_name', value:true})}}>
               Si
              </Button>
              <Button
                onClick={()=>{this.setState({hidden: false}); 
                manualChange({var: 'rfc_name', value:false});
                manualChange({var: 'messageAlert', value:''});
                manualChange({var: 'employeeInfo', value:''})
                manualChange({var: 'activeStep', value:1});
                }}
                className={classes.backButton}
              >
                No
              </Button>
              </Grid>

              <TextField 
              className={classes.margin} 
              id="outlined-basic" variant="outlined" 
              label='Ingrese su RFC' 
              labelWidth= {50}
              onChange={handleChange('rfc')}
              defaultValue={values.rfc}
              //style={this.state.hidden ? {labelWidth:50} : { display: 'none' }}
              style={{visibility: this.state.hidden ? 'visible' : 'hidden' }}
              />
            </Grid>
        )
    }
}

EnterRFCName.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(EnterRFCName)