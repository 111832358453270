import React from "react"
import PropTypes from 'prop-types';
import Layout from "../../components/layout"
import { Typography, Card, CardContent, Button, Step, StepLabel, Stepper, Grid} from "@material-ui/core"
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles} from '@material-ui/core/styles';
import EnterRFCName from './RFCName'
import EnterDetails from './details'
import EmployeeDetails from "./employeeDetails";
import CreateConsult from '../../api/tickets/createConsult'

const styles = theme =>({
    card: {
      minWidth: 275,
      fontFamily:"Arial Black"
    },
    title: {
      fontSize: 14,
      fontFamily:"Arial Black"
      
    },
    margin: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    fontFamily:"Arial Black"
    },
    backButton: {
      marginRight: theme.spacing(1),
      
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontFamily:"Montserrat"
    },
    messageAlert: {
     // textAlign: 'center',
      //alignItems: 'center',
      color: 'red'
    },
    font:{
      fontFamily:"Montserrat"
    }
  });

  function getSteps() {
    return ['Ingresar de RFC o Nombre', 'Confirmacion de Datos de trabajador','Ingresar detalles'];
  }
/*   function EnterRFCName(){
    const classes = styles(createMuiTheme());
    const handleChange = event => {
      this.setState({rfc_name:!this.state.rfc_name});
    };
    return(
    <Grid>
      <RadioGroup defaultValue="rfc" aria-label="rfc" className={classes.formControl} onChange={handleChange}>
      <FormControlLabel
          value="rfc"
          control={<Radio color="primary" />}
          label="Con RFC"
          //labelPlacement="start"
        />
        <FormControlLabel
          value="sinRfc"
          control={<Radio color="primary" />}
          label="Sin RFC"
          //labelPlacement="start"
        />
    </RadioGroup>
    <TextField className={classes.margin} id="outlined-basic" variant="outlined" label={this.state.rfc_name? 'Ingrese su RFC': 'Ingrese su Nombre'}/>
    </Grid>
    )
  } */
class Consultas extends React.Component {
  constructor() {
    super();
    // No hagas esto!
    this.state = { 
      rfc_name: false,
      rfc: '',
      email: '',
      tel: '',
      consultType: '',
      employeeInfo: '',
      details:'',
      activeStep: 0,
      messageAlert: '',
      companyUuid: '',
      name:'',
      resultCreate: {status: 'error', data: {message:'Error en la conexion Intentelo mas tarde'}}
     };
   }

  handleChange = input => event => {
    event.preventDefault();
    this.setState({[input]: event.target.value});
    if(input==='details'||input==='consultType')
    this.setState({messageAlert: ''})
  };

  manualChange = item =>{
    this.setState({[item.var]: item.value})
  }
  checkEmailPhone= who=>{
    if (who.var==='tel'){
      const regTel = new RegExp(/^\d{10}$/)///\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);
      if(!regTel.test(who.value)&& who.value!==''){
        this.setState({
          messageAlert:'Ingrese un telefono valido.',
        })
        return false
      }else{
        this.setState({
          messageAlert:'',
        })
        return true
      }
    }else if (who.var==='email'){
      const regemail = new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)
        ///^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      if(!regemail.test(who.value)&& who.value!==''){
        this.setState({
          messageAlert:'Ingrese un email valido.',
        })
        return false
      }else{
        this.setState({
          messageAlert:'',
        })
        return true
      }
    }

  }
  
  render(){  
    const { classes } = this.props;
    const {rfc_name, rfc, email, tel, consultType, employeeInfo, details, activeStep, messageAlert,companyUuid, name} =this.state;
    const values = {rfc_name, rfc, email, tel, consultType, employeeInfo, details, activeStep, messageAlert, companyUuid, name};
    
    const SendTicket = async()=>{
     // console.log(this.state.companyUuid)
      const data = {
        companyUuid,//: this.state.companyUuid,
        rfc: rfc_name?rfc: '',
        optionalDataInputs: {name, phone: tel, email, employee: ''},
        details: details,
        consultationTypeUuid: consultType,
        highPriority: rfc_name
      }
       const result = await CreateConsult(data);
      this.setState({resultCreate: result})
    }

    //const [this.state.activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
  
    const handleNext = () => {
      //console.log(this.state)
      if(Validations(this.state.activeStep)){
        this.setState({
          activeStep: this.state.activeStep + 1,
          messageAlert:''
        })
      }
      
      //setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const Validations =(step)=>{
      switch (step) {
        case 0:
          const pattern = new RegExp(/^[A-ZÑ]{3,4}(\d{6})((\D|\d){1,3})?$/)
          if (pattern.test(rfc.replace(/ /g, '').replace(/-/g, ''))&& rfc_name){
            this.setState({
              employeeInfo: '',
              rfc: rfc.replace(/ /g, '').replace(/-/g, '')
            })
            return true
          } 
          else if(rfc_name){
            this.setState({
              messageAlert:'Ingrese un RFC valido para continuar.',
              employeeInfo: '',
            })
            return false
          } 
          else return true
        case 1:
          //console.log(name, companyUuid)
          if (!rfc_name){
            if(name==='' || companyUuid===''){
                this.setState({messageAlert: 'Por favor seleccione una empresa o Ingrese su nombre.'})
                return false
            }
          }
          this.setState({messageAlert: ''})
          return true
        case 2:
            if(!this.checkEmailPhone({var:'tel', value: tel}))
                return false
            else if(!this.checkEmailPhone({var:'email', value: email}))
              return false
          if((email===''&& tel==='')){
            this.setState({messageAlert: 'Ingrese alguno de los datos de contacto para que el asesor se comunique con usted.'})      
            return false
          }else if(details===''){
            this.setState({messageAlert: 'Ingrese los detalles de su consulta.'})
            return false
          }else if(consultType===''){
            this.setState({messageAlert: 'Seleccione el tipo de consulta.'})
            return false
          }else if(messageAlert!=='' && !(email===''&& tel==='') ) return false
          //console.log(messageAlert!=='' && !(email===''&& tel===''))
          SendTicket()
          return true
        default: 
          return true
      }
    }

    const handleBack = () => {
      this.setState({activeStep: this.state.activeStep - 1})
      this.setState({messageAlert: ''})
      //setActiveStep(prevActiveStep => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      //this.setState({activeStep: 0})
      window.location = '/'
      //setActiveStep(0);
    };

    const getStepContent = stepIndex => {
    
      switch (stepIndex) {
        case 0:
          return <EnterRFCName
          handleChange={this.handleChange}
          values = {values}
          manualChange = {this.manualChange}
          />;
        case 1: 
          return <EmployeeDetails
          manualChange = {this.manualChange}
          handleChange={this.handleChange}
          values = {values}
          />;
        case 2:
          return <EnterDetails
          handleChange={this.handleChange}
          manualChange = {this.manualChange}
          checkEmailPhone = {this.checkEmailPhone}
          values = {values}
          />;
        default:
          return 'Unknown stepIndex';
      }
    }
    return(
  <Layout>
    <Typography className={classes.margin} variant="h5">Consulta</Typography>
    <div className={classes.root}>
      <Stepper activeStep={this.state.activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel className={classes.font} >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {this.state.activeStep === steps.length ? (
          <Grid
          container
            spacing={1}
            direction="row"
            alignItems="center"
            justify='center'>
             <Typography variant='h5'>{(this.state.resultCreate.status==='success')?
        ('Consulta enviada'): ('Error en la creacion de consulta')} </Typography>
        <Typography className={classes.instructions}>{(this.state.resultCreate.status==='success')?
        ('Su consulta ha sido enviada, mantengase al tanto para la resolucion de su problema.\n Su numero de seguimiento es: '+ this.state.resultCreate.data.consultation.id): ('Error\n' + this.state.resultCreate.data.message)} </Typography>
            <Button variant="contained" onClick={handleReset}>Ir a pagina principal</Button>
          </Grid>
        ) : (
          <div>
             <Card className={classes.card2}>
          <CardContent >
            {getStepContent(this.state.activeStep)}
            <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-end"
            //alignItems="center"
            className={ classes.messageAlert}
            style={this.state.messageAlert!=='' ? {} : { display: 'none' }}
            >
            <ErrorIcon/>
            <Typography >{this.state.messageAlert}</Typography>
            </Grid>
            </CardContent>
            </Card>
            <Grid
                  className={ classes.margin}
                  container
                  spacing={1}
                  direction="column"
                  alignItems="flex-end"
                  justify="center"
                //style={{ minHeight: '100vh' }}
                >
              <Grid item>
              <Button
                  variant="contained"
                disabled={this.state.activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Regresar
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {this.state.activeStep === steps.length - 1 ? 'Enviar Consulta' : 'Siguiente'}
              </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </div>
  </Layout>
)}}
Consultas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Consultas)
