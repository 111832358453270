import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField, Select, FormControl, InputLabel, MenuItem, Grid} from "@material-ui/core"
import { withStyles} from '@material-ui/core/styles';
import ConsultType from '../../api/tickets/getConsultTypes'

const styles = theme =>({
    formControl: {
      //margin: theme.spacing(0),
      margin: theme.spacing(1),
    },
    title: {
      fontSize: 14,
      marginTop: theme.spacing(2)
    },
    margin: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    },
    select:{
      margin: theme.spacing(1),
      minWidth: 200,
    }
  });


export class EnterDetails extends React.Component{
  constructor(props){
    super(props);
    this.state={
      consultTypes:''
    }
  }
  async componentDidMount(){
    const consultTypes = await ConsultType()
    //console.log(consultTypes)
    this.setState({consultTypes: consultTypes.data.consultationTypes})
    this.props.manualChange({var:'consultType', value:''})
  }
  checker= (data)=>{
    
  }
    render() {
      const { values, handleChange, manualChange, checkEmailPhone } = this.props;
        const { classes } = this.props;
        if (!values)
          return null
        return(
          <div className={classes.card}>
            <Typography variant="h5" color="textSecondary" gutterBottom>
              Rellene el formulario
              </Typography>
              <Typography variant= 'h6' className={classes.title}> Datos de contacto</Typography>
              <Grid
               container
               direction="row"
               justify="flex-start"
               alignItems="center"
              >
            <TextField 
            className={classes.margin}
            id="outlined-basic" 
            variant="outlined" label= 'Ingrese un numero de telefono'
            onChange={(event)=>{ 
              manualChange({var:'tel', value: event.target.value})
              checkEmailPhone({var:'tel', value: event.target.value})}}
            value={values.tel}
            />
               <Typography align='justify' className={classes.bodyText}  component="p">y/ó</Typography>
             <TextField 
            className={classes.margin}
            id="outlined-basic" 
            variant="outlined" label= 'Ingrese su email'
            onChange={(event)=>{ 
              manualChange({var:'email', value: event.target.value})
              checkEmailPhone({var:'email', value: event.target.value})}}
            value={values.email}
            />
            </Grid>
            <Typography variant= 'h6' className={classes.title}>Detalles de consulta</Typography>
     {/*        <TextField 
            className={classes.margin}
            id="outlined-basic" 
            variant="outlined" label= 'Ingrese el tipo de consulta'
            onChange={handleChange('consultType')}
            value={values.consultType}
            /> */}
            {this.state.consultTypes.length > 0 ? ( 
                        <FormControl variant="outlined" className={classes.select}>
                        <InputLabel id="demo-simple-select-outlined-label">Seleccione el tipo de consulta</InputLabel>
                        <Select
                        labelId="demo-simple-select-outlined-label"
                         id="demo-simple-select-outlined"
                        value={values.consultType}
                        //defaultValue={values.companyUuid}
                        name='consultType'
                        onChange= {handleChange('consultType')}
                        /* onChange={(event)=>{ 
                          manualChange({var:'consultType', value: event.target.value})
                          if (values.messageAlert ==='Seleccione el tipo de consulta.'){
                          manualChange({var:'messageAlert', value: ''})}}
                        } */
                        labelWidth= {215}
                        >
                    {this.state.consultTypes.map((consult,index) => {
                        return (
                            <MenuItem value={consult.uuid} key={consult.uuid}>{consult.content}</MenuItem>
                        );
                    })}
                    </Select>
                    </FormControl>
                    ) : (                              
                    <FormControl variant="outlined" className={classes.inputCompanie}>
                        <InputLabel id="demo-simple-select-outlined-label">Seleccione el tipo de consulta</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            labelWidth= {179}
                            >
                            <MenuItem value={0}>_</MenuItem>
                            </Select>
                    </FormControl>
                     )}
            <TextField
              className={classes.margin}
              id="outlined-multiline-static"
              label="Ingrese la consulta"
              multiline
              rows="10"
              variant="outlined"
              fullWidth
              onChange= {handleChange('details')}
              value= { values.details}
            />
        </div>
    
        )
    }
}

EnterDetails.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(EnterDetails)