import api from "../baseurl";

const Show = async (data) => {
    //console.log(data)
    const status = await api.post("consultations/create", {json: {
        companyUuid: data.companyUuid,
        rfc: data.rfc,
        optionalDataInputs: data.optionalDataInputs,
        details: data.details,
        consultationTypeUuid: data.consultationTypeUuid,
        highPriority: data.highPriority
    }}).json();
    //console.log(status)
    return status ;
    
  };
  
  export default Show ; 
